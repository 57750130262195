@import '../../styles/common/variables.scss';

.specialPriceLabelContainer {
    position: relative;
}

.specialPriceLabel {
    position: absolute;
    background-color: $secondary-orange-400;
    border-radius: 9999px;
    padding: 5px 20px;
    color: $accent-white;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; // Prevents text from wrapping to the next line
    top: -15px;
}

.selected {
    background-color: $primary-green-50 !important;
    outline-color: $primary-green-400;
    outline-width: 2px;
    outline-offset: 2px;
    outline-style: solid;
}