@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';

.image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
}

.cardItem {
    position: relative;
    overflow: hidden;

    a {
        text-decoration: none;
        display: inline-block;
        background-color: $primary-green-50;
        width: 100%;

        &:hover {
            background-color: $primary-green-100;
        }

        &:focus {
            outline: 2px solid $interactive-blue;
            outline-offset: 2px;
        }

        &:active {
            background-color: $primary-green-200;
        }
    }
}

.diagonalBanner {
    position: absolute;
    top: 100px;
    left: -50%;
    width: 200%;
    background-color: $secondary-orange-400;
    color: $accent-white;
    text-align: center;
    transform: rotate(-10deg);
    transform-origin: center;
    z-index: 10;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // Add shadow here
    display: flex;
    flex-direction: column; // Stack children vertically
    align-items: center;
    justify-content: center;
    padding: 0; // Add padding to ensure some space around the text
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); // Add faded border to text
    line-height: normal; // Reset line-height to normal for multi-line text
}

.appliesUntil {
    @include font-standard;
    font-size: 11px;
    color: $accent-white;
    line-height: normal;
    text-align: right;
    width: 100%;
    padding-right: 26%;
}

.bannerDescription {
    @include font-standard;
    font-size: 11px;
    color: $accent-white;
    line-height: normal;
    width: 100%;
    padding-right: 26%;
    padding-left: 26%;

}

.ordinaryPrice {
    text-decoration-line: line-through;
    text-decoration-color: $secondary-black-400;
}