@import "../../styles/common/variables.scss";

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($primary-green-300, 0.4); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; // Ensure this is higher than any other elements

    @media (max-width: $screen-sm) {
        background: none; // Hide backdrop on mobile
    }

    &.modalBackdropBlur {
        backdrop-filter: blur(5px);
    }
}

.modalContent {
    position: relative;
    z-index: 2001; // Ensure this is higher than the backdrop
    pointer-events: auto; // Allow clicks on the modal content
    min-width: 500px; 

    @media (max-width: $screen-lg) {
        width: 100%;
        height: calc(100% - 80px);
        top: 40px;
        border-radius: 0;
        min-width: unset;
    }
}

.modalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px; // Adjust the size as needed
    height: 16px; // Adjust the size as needed
    padding: 10px;
    background: url('/assets/img/icons/icon_close.svg') no-repeat center center;
    background-size: contain;
    border: none;
    cursor: pointer;
}

.modalCloseOutline {
    background: url('/assets/img/icons/icon_close_outline.svg') no-repeat center center;
}

.modalCloseTopRight {
    background-color: #fff;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 2002; // Ensure this is higher than the modal content
    padding: 20px;
    width: 50px; // Adjust the size as needed
    height: 50px;
}

.modalContentNoFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px;

    @media (max-width: $screen-lg) {
        padding: 0px 50px;
        height: calc(100% - 80px);
        top: 40px;
        border-radius: 0;
    }
}