@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';

.imageFull {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;

    @media (max-width: $screen-md) {
        position: relative;
        height: auto;
        width: 100%;
        aspect-ratio: 327 / 267.79;
    }
}

.image {
    max-height: 80px;
    max-width: 80px;
}

.cardBorder {
    border: 1px solid $accent-grey;
}

.card {
    @media (max-width: $screen-md) {
        padding: 0; /* Remove padding in mobile view */
        height: auto; /* Allow height to be determined by content */
    }
}