@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';

.breadcrumbLink {
    @include font-standard;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-black-400;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
        text-decoration: none;
    }
}

.separator {
    @include font-standard;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-black-400;
    font-weight: 500;
}

.activePage {
    @include font-standard;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-black-400;
    font-weight: bold;
}