@import '../../styles/common/variables.scss';
.current {
    font-size: 14px;
    font-family: "Quicksand Variable", sans-serif;
    line-height: 16px;
    color: $secondary-black-300;
}

.disabled {
    font-size: 14px;
    font-family: "Quicksand Variable", sans-serif;
    line-height: 16px;
    color: $secondary-black-200;
}

.ellipsis {
    font-size: 16px;
    font-family: "Quicksand Variable", sans-serif;
    line-height: 16px;
}

.paginationButton {
    text-decoration: none;
}