@import "../../styles/common/mixins.scss";

.mapMarkerContainer {
    min-width: 150px;
}

.streetAddress {
    @include font-standard;

}

.city {
    @include font-standard;

}

.lowestPrice {
    @include helper-text;
}